<template lang="pug">
  footer.footer
    div.footer__container
      div.footer__top
        router-link.footer__logo( v-scroll-to="'#steps1'", :to="{ name: 'HomePage' }" )
          v-img(:src="LogoFooter", alt="logo")
        div.footer__actions
          a.footer__link-actions.footer__link-actions--tel(href="tel:+380630203007") +380 63 02 03 007
          a.footer__link-actions.footer__link-actions--mail(href="mailto:mail@drivelink.com.ua") mail@drivelink.com.ua
          a.footer__link-actions.footer__link-actions--inst(href="https://www.instagram.com/drivelink.com.ua/") @drivelink.com.ua
        nav.footer__menu.menu
          ul.footer__list
            li.footer__item
              router-link.footer__link( to="", v-scroll-to="'#steps1'" ) Головна
            li.footer__item
              router-link.footer__link( to="", v-scroll-to="'#steps2'" ) Про Drivelink
            li.footer__item
              router-link.footer__link( to="", v-scroll-to="'#steps3'" ) Характеристики
            li.footer__item
              router-link.footer__link( to="", v-scroll-to="'#steps4'" ) Ціни
            li.footer__item
              router-link.footer__link( to="", v-scroll-to="'#steps5'" ) Контакти
      div.footer__copyright
        p 2023 Drivelink.
        span |
        p Всі права захищено.
</template>

<script>
import LogoFooter from '@/assets/images/logo-footer.svg'

export default {
  name: 'FooterBlock',
  data () {
    return {
      LogoFooter
    }
  }
}
</script>
